const mediaDictionary = {
  "Default": {
    "images": [],
    "videos": ["Mahalaxmi-Bg-Video.mp4"],
  },
  "Plot 1": {
    "images": ["plot1.png", "plot9.jpg"],
    "videos": ["plot4.mp4"],
  },
  "Plot 2": {
    "images": ["plot2.png"],
    "videos": [],
  },
  "Plot 3": {
    "images": [],
    "videos": ["plot3.mp4"],
  },
  "Plot 4": {
    "images": [],
    "videos": ["plot4.mp4"],
  },
  "Plot 5": {
    "images": [],
    "videos": ["plot5.mp4"],
  },
  "Plot 6": {
    "images": [],
    "videos": ["plot6.mp4"],
  },
  "Plot 7": {
    "images": ["plot7.jpg"],
    "videos": ["plot5.mp4"],
  },
  "Plot 8": {
    "images": ["plot8.jpg"],
    "videos": [],
  },
  "Plot 9": {
    "images": ["plot9.jpg"],
    "videos": [],
  },
  "Plot 10": {
    "images": ["plot10.jpg"],
    "videos": [],
  },
};

export default mediaDictionary