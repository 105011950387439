const LivingAreaData = [
  {
    "time": 6,
    "type": "word",
    "start": 0,
    "end": 4,
    "value": "Step"
  },
  {
    "time": 6,
    "type": "viseme",
    "value": "s"
  },
  {
    "time": 118,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 177,
    "type": "viseme",
    "value": "E"
  },
  {
    "time": 249,
    "type": "viseme",
    "value": "p"
  },
  {
    "time": 312,
    "type": "word",
    "start": 5,
    "end": 11,
    "value": "inside"
  },
  {
    "time": 312,
    "type": "viseme",
    "value": "i"
  },
  {
    "time": 358,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 430,
    "type": "viseme",
    "value": "s"
  },
  {
    "time": 531,
    "type": "viseme",
    "value": "a"
  },
  {
    "time": 676,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 716,
    "type": "word",
    "start": 12,
    "end": 15,
    "value": "our"
  },
  {
    "time": 716,
    "type": "viseme",
    "value": "a"
  },
  {
    "time": 775,
    "type": "viseme",
    "value": "r"
  },
  {
    "time": 858,
    "type": "word",
    "start": 16,
    "end": 24,
    "value": "spacious"
  },
  {
    "time": 858,
    "type": "viseme",
    "value": "s"
  },
  {
    "time": 972,
    "type": "viseme",
    "value": "p"
  },
  {
    "time": 1031,
    "type": "viseme",
    "value": "e"
  },
  {
    "time": 1135,
    "type": "viseme",
    "value": "S"
  },
  {
    "time": 1249,
    "type": "viseme",
    "value": "@"
  },
  {
    "time": 1300,
    "type": "viseme",
    "value": "s"
  },
  {
    "time": 1411,
    "type": "word",
    "start": 25,
    "end": 31,
    "value": "living"
  },
  {
    "time": 1411,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 1501,
    "type": "viseme",
    "value": "i"
  },
  {
    "time": 1543,
    "type": "viseme",
    "value": "f"
  },
  {
    "time": 1604,
    "type": "viseme",
    "value": "i"
  },
  {
    "time": 1646,
    "type": "viseme",
    "value": "k"
  },
  {
    "time": 1724,
    "type": "word",
    "start": 32,
    "end": 36,
    "value": "room"
  },
  {
    "time": 1724,
    "type": "viseme",
    "value": "r"
  },
  {
    "time": 1825,
    "type": "viseme",
    "value": "u"
  },
  {
    "time": 1927,
    "type": "viseme",
    "value": "p"
  },
  {
    "time": 2080,
    "type": "viseme",
    "value": "sil"
  },
  {
    "time": 2274,
    "type": "word",
    "start": 38,
    "end": 44,
    "value": "bathed"
  },
  {
    "time": 2274,
    "type": "viseme",
    "value": "p"
  },
  {
    "time": 2326,
    "type": "viseme",
    "value": "e"
  },
  {
    "time": 2504,
    "type": "viseme",
    "value": "T"
  },
  {
    "time": 2586,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 2655,
    "type": "word",
    "start": 45,
    "end": 47,
    "value": "in"
  },
  {
    "time": 2655,
    "type": "viseme",
    "value": "i"
  },
  {
    "time": 2702,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 2752,
    "type": "word",
    "start": 48,
    "end": 55,
    "value": "natural"
  },
  {
    "time": 2752,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 2817,
    "type": "viseme",
    "value": "a"
  },
  {
    "time": 2947,
    "type": "viseme",
    "value": "S"
  },
  {
    "time": 3060,
    "type": "viseme",
    "value": "r"
  },
  {
    "time": 3098,
    "type": "viseme",
    "value": "@"
  },
  {
    "time": 3128,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 3170,
    "type": "word",
    "start": 56,
    "end": 61,
    "value": "light"
  },
  {
    "time": 3170,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 3292,
    "type": "viseme",
    "value": "a"
  },
  {
    "time": 3505,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 3616,
    "type": "viseme",
    "value": "sil"
  },
  {
    "time": 3812,
    "type": "word",
    "start": 63,
    "end": 65,
    "value": "it"
  },
  {
    "time": 3812,
    "type": "viseme",
    "value": "i"
  },
  {
    "time": 3879,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 3919,
    "type": "word",
    "start": 66,
    "end": 68,
    "value": "is"
  },
  {
    "time": 3919,
    "type": "viseme",
    "value": "i"
  },
  {
    "time": 3994,
    "type": "viseme",
    "value": "s"
  },
  {
    "time": 4050,
    "type": "word",
    "start": 69,
    "end": 77,
    "value": "designed"
  },
  {
    "time": 4050,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 4099,
    "type": "viseme",
    "value": "i"
  },
  {
    "time": 4151,
    "type": "viseme",
    "value": "s"
  },
  {
    "time": 4230,
    "type": "viseme",
    "value": "a"
  },
  {
    "time": 4362,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 4392,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 4436,
    "type": "word",
    "start": 78,
    "end": 81,
    "value": "for"
  },
  {
    "time": 4436,
    "type": "viseme",
    "value": "f"
  },
  {
    "time": 4501,
    "type": "viseme",
    "value": "O"
  },
  {
    "time": 4539,
    "type": "viseme",
    "value": "r"
  },
  {
    "time": 4575,
    "type": "word",
    "start": 82,
    "end": 86,
    "value": "both"
  },
  {
    "time": 4575,
    "type": "viseme",
    "value": "p"
  },
  {
    "time": 4666,
    "type": "viseme",
    "value": "o"
  },
  {
    "time": 4786,
    "type": "viseme",
    "value": "T"
  },
  {
    "time": 4866,
    "type": "word",
    "start": 87,
    "end": 97,
    "value": "relaxation"
  },
  {
    "time": 4866,
    "type": "viseme",
    "value": "r"
  },
  {
    "time": 4961,
    "type": "viseme",
    "value": "i"
  },
  {
    "time": 5019,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 5111,
    "type": "viseme",
    "value": "a"
  },
  {
    "time": 5215,
    "type": "viseme",
    "value": "k"
  },
  {
    "time": 5289,
    "type": "viseme",
    "value": "s"
  },
  {
    "time": 5386,
    "type": "viseme",
    "value": "e"
  },
  {
    "time": 5491,
    "type": "viseme",
    "value": "S"
  },
  {
    "time": 5618,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 5728,
    "type": "word",
    "start": 98,
    "end": 101,
    "value": "and"
  },
  {
    "time": 5728,
    "type": "viseme",
    "value": "@"
  },
  {
    "time": 5784,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 5828,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 5877,
    "type": "word",
    "start": 102,
    "end": 115,
    "value": "entertainment"
  },
  {
    "time": 5877,
    "type": "viseme",
    "value": "E"
  },
  {
    "time": 5970,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 6013,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 6047,
    "type": "viseme",
    "value": "E"
  },
  {
    "time": 6113,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 6224,
    "type": "viseme",
    "value": "e"
  },
  {
    "time": 6322,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 6372,
    "type": "viseme",
    "value": "p"
  },
  {
    "time": 6426,
    "type": "viseme",
    "value": "@"
  },
  {
    "time": 6504,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 6553,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 6670,
    "type": "viseme",
    "value": "sil"
  }
]

export default LivingAreaData
