const ServerErrorData = [
  {
    "time": 6,
    "type": "word",
    "start": 0,
    "end": 5,
    "value": "Sorry"
  },
  {
    "time": 6,
    "type": "viseme",
    "value": "s"
  },
  {
    "time": 149,
    "type": "viseme",
    "value": "a"
  },
  {
    "time": 273,
    "type": "viseme",
    "value": "r"
  },
  {
    "time": 366,
    "type": "viseme",
    "value": "i"
  },
  {
    "time": 642,
    "type": "viseme",
    "value": "sil"
  },
  {
    "time": 836,
    "type": "word",
    "start": 7,
    "end": 8,
    "value": "I"
  },
  {
    "time": 836,
    "type": "viseme",
    "value": "a"
  },
  {
    "time": 958,
    "type": "word",
    "start": 9,
    "end": 15,
    "value": "didn't"
  },
  {
    "time": 958,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 1025,
    "type": "viseme",
    "value": "i"
  },
  {
    "time": 1090,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 1127,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 1215,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 1246,
    "type": "word",
    "start": 16,
    "end": 19,
    "value": "get"
  },
  {
    "time": 1246,
    "type": "viseme",
    "value": "k"
  },
  {
    "time": 1292,
    "type": "viseme",
    "value": "E"
  },
  {
    "time": 1360,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 1449,
    "type": "word",
    "start": 20,
    "end": 24,
    "value": "that"
  },
  {
    "time": 1449,
    "type": "viseme",
    "value": "T"
  },
  {
    "time": 1489,
    "type": "viseme",
    "value": "a"
  },
  {
    "time": 1672,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 1805,
    "type": "viseme",
    "value": "sil"
  },
  {
    "time": 2199,
    "type": "word",
    "start": 26,
    "end": 29,
    "value": "Can"
  },
  {
    "time": 2199,
    "type": "viseme",
    "value": "k"
  },
  {
    "time": 2272,
    "type": "viseme",
    "value": "a"
  },
  {
    "time": 2310,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 2360,
    "type": "word",
    "start": 30,
    "end": 33,
    "value": "you"
  },
  {
    "time": 2360,
    "type": "viseme",
    "value": "i"
  },
  {
    "time": 2417,
    "type": "viseme",
    "value": "u"
  },
  {
    "time": 2469,
    "type": "word",
    "start": 34,
    "end": 37,
    "value": "say"
  },
  {
    "time": 2469,
    "type": "viseme",
    "value": "s"
  },
  {
    "time": 2576,
    "type": "viseme",
    "value": "e"
  },
  {
    "time": 2713,
    "type": "word",
    "start": 38,
    "end": 40,
    "value": "it"
  },
  {
    "time": 2713,
    "type": "viseme",
    "value": "i"
  },
  {
    "time": 2760,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 2810,
    "type": "word",
    "start": 41,
    "end": 46,
    "value": "again"
  },
  {
    "time": 2810,
    "type": "viseme",
    "value": "@"
  },
  {
    "time": 2870,
    "type": "viseme",
    "value": "k"
  },
  {
    "time": 2960,
    "type": "viseme",
    "value": "E"
  },
  {
    "time": 3089,
    "type": "viseme",
    "value": "t"
  },
  {
    "time": 3256,
    "type": "viseme",
    "value": "sil"
  }
]

export default ServerErrorData